.skeleton {
  background: #1e293b;
  margin: 16px 0;
  border-radius: 4px;
}

.skeleton.text {
  height: 12px;
  width: 100%;
}

.skeleton.title {
  width: 50%;
  height: 24px;
  margin-bottom: 24px;
}

.skeleton.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.skeleton.thumbnail {
  width: 100%;
  height: 200px;
}

.shimmer {
  animation: shimmer 1.25s infinite linear;
  background: linear-gradient(to right, #1e293b 8%, #1d2e3f 18%, #1e293b 33%);
  background-size: 1000px 100%;
  height: 100%;
  width: 100%;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
