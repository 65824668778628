@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

html,body{
    font-family: "Poppins"  !important;
}

.ant-modal-header{
    border-radius: 8px !important;
}

.ant-modal-content{
    border-radius: 8px !important;
}

.ant-select-selector{
    border-radius: 100px !important;
}

#tagscard .ant-card-body{
    padding: 0 !important;
}